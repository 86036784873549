import { countries } from 'constants/countries';
import { currencies } from 'constants/currencies';
import { languages as supportedLanguages } from 'constants/languages';

import React from 'react';

import { Grid, Box, Divider, IconButton, Icon } from '@mui/material';
import {
  MultipleSelectField,
  TextInputField,
  SelectInputField,
  SwitchField,
  CountrySelect,
} from 'components/FormField';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import { useFieldArray, useFormContext } from 'react-hook-form';

const VersionForm = () => {
  const { control } = useFormContext();
  const {
    fields: limits,
    append: appendLimit,
    remove: removeLimit,
  } = useFieldArray({ control, name: 'limits' });
  const {
    fields: languages,
    append: appendLanguage,
    remove: removeLanguage,
  } = useFieldArray({ control, name: 'languages' });
  const {
    fields: currenciesWhitelist,
    append: appendCurrencyWhitelist,
    remove: removeCurrencyWhitelist,
  } = useFieldArray({ control, name: 'currencies_whitelist' });

  const currenciesOptions = currencies.slice(1, -1).map((currency) => ({
    label: currency.code,
    value: currency.code,
  }));

  const languagesOptions = supportedLanguages.map((language) => ({
    label: language.name,
    value: language.code,
  }));

  const paymentMethodsOptions = [
    {
      label: 'Card',
      value: 'Card',
    },
    {
      label: 'Mobile',
      value: 'Mobile',
    },
    {
      label: 'Bank Transfer',
      value: 'Bank Transfer',
    },
  ];

  const countriesOptions = countries
    .slice(1, -1)
    .map((country) => ({ label: country.label, value: country.code }));

  return (
    <SoftBox>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextInputField label='Version Name' name='name' />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextInputField label='Base URL' name='base_url' />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <MultipleSelectField
            label='Countries'
            name='countries'
            options={countriesOptions}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6}>
          <MultipleSelectField
            label='Payment methods'
            name='payment_methods'
            options={paymentMethodsOptions}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <SelectInputField
            options={languagesOptions}
            label={`Default language`}
            name={`default_language`}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <SoftBox display='flex' ml={2}>
            <SoftBox>
              <SwitchField label='' name='enabled' />
            </SoftBox>
            <SoftBox width='80%'>
              <SoftTypography
                variant='button'
                fontWeight='regular'
                color='text'
              >
                Enabled version
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
      <Divider
        textAlign='left'
        sx={{
          opacity: 1,
          backgroundImage: 'none !important',
          height: 'auto',
        }}
      >
        <SoftTypography variant='caption'>Currencies settings</SoftTypography>
      </Divider>
      <SoftBox ml={6}>
        {_.map(limits, (field, index) => (
          <Grid container key={field.id}>
            <Grid item xs={10.5}>
              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <SelectInputField
                      options={currenciesOptions}
                      label={`currency`}
                      name={`limits[${index}].currency`}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} lg={6}>
                    <TextInputField
                      required
                      label={`Min`}
                      name={`limits[${index}].min`}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <TextInputField
                      required
                      label={`Max`}
                      name={`limits[${index}].max`}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider />
            </Grid>

            <Grid item xs={1}>
              <IconButton onClick={() => removeLimit(index)} sx={{ mt: 2 }}>
                <Icon>delete_forever</Icon>
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <SoftButton
          sx={{ mt: 2 }}
          onClick={() => appendLimit({ currency: '', min: 0, max: 0 })}
        >
          Add Currency
        </SoftButton>
      </SoftBox>
      <Divider
        textAlign='left'
        sx={{
          opacity: 1,
          backgroundImage: 'none !important',
          height: 'auto',
        }}
      >
        <SoftTypography variant='caption'>language settings</SoftTypography>
      </Divider>
      <SoftBox ml={6}>
        {_.map(languages, (field, index) => (
          <Grid container key={field.id}>
            <Grid item xs={10.5}>
              <Box>
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    <CountrySelect name={`languages[${index}].country`} />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <SelectInputField
                      options={languagesOptions}
                      label={`default language`}
                      name={`languages[${index}].language`}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider />
            </Grid>

            <Grid item xs={1}>
              <IconButton onClick={() => removeLanguage(index)} sx={{ mt: 2 }}>
                <Icon>delete_forever</Icon>
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <SoftButton
          sx={{ mt: 2 }}
          onClick={() => appendLanguage({ country: '', language: '' })}
        >
          Add Language
        </SoftButton>
      </SoftBox>
      <Divider
        textAlign='left'
        sx={{
          opacity: 1,
          backgroundImage: 'none !important',
          height: 'auto',
        }}
      >
        <SoftTypography variant='caption'>
          currencies whitelist settings
        </SoftTypography>
      </Divider>
      <SoftBox ml={6}>
        {_.map(currenciesWhitelist, (field, index) => (
          <Grid container key={field.id}>
            <Grid item xs={10.5}>
              <Box>
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    <CountrySelect
                      name={`currencies_whitelist[${index}].country`}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <MultipleSelectField
                      label='currencies'
                      name={`currencies_whitelist[${index}].currencies`}
                      options={currenciesOptions}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider />
            </Grid>

            <Grid item xs={1}>
              <IconButton
                onClick={() => removeCurrencyWhitelist(index)}
                sx={{ mt: 2 }}
              >
                <Icon>delete_forever</Icon>
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <SoftButton
          sx={{ mt: 2 }}
          onClick={() =>
            appendCurrencyWhitelist({ country: '', currencies: [] })
          }
        >
          Add whitelist
        </SoftButton>
      </SoftBox>
    </SoftBox>
  );
};
export default VersionForm;
