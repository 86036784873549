import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Card, Stack, DialogActions } from '@mui/material';
import FormProvider from 'components/FormProviders';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import DeleteVersionModal from 'pages/versions/components/DeleteVersionModal';
import VersionForm from 'pages/versions/components/VersionForm';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useGetVersion, useUpdateVersion } from '../hooks';
import {
  schema,
  defaultValues,
  sanitizeVersionRequest,
} from '../schemas/version';
import DeleteFeeModal from './DeleteFeeModal';

function VersionTab() {
  const { id } = useParams();

  const { data, isLoading: isFetchingVersion } = useGetVersion({ id });
  const { mutate: updateVersion, isLoading } = useUpdateVersion({ id });
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = (values) => {
    const versionPayload = sanitizeVersionRequest(values);
    updateVersion(versionPayload);
  };

  useEffect(() => {
    reset(data);
  }, [data]);

  if (isFetchingVersion) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={2} sm={2} />
      <Grid item xs={8} sm={8}>
        <Card sx={{ overflow: 'visible' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack lineHeight={0} m={3}>
              <SoftTypography variant='h5' fontWeight='bold'>
                Version Settings
              </SoftTypography>
              <SoftTypography
                variant='button'
                fontWeight='regular'
                color='text'
              >
                manage/update version details
              </SoftTypography>
            </Stack>
            <Stack m={3}>
              <VersionForm />
            </Stack>
            <Stack ml={1}>
              <DialogActions style={{ justifyContent: 'flex-start' }}>
                <SoftButton
                  variant='gradient'
                  color='info'
                  type='submit'
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Update
                </SoftButton>
                <DeleteVersionModal version={data} display={'button'} />
              </DialogActions>
            </Stack>
          </FormProvider>
        </Card>
      </Grid>
    </Grid>
  );
}

export default VersionTab;
