import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Card, Stack, DialogActions } from '@mui/material';
import { TextInputField, SelectInputField } from 'components/FormField';
import FormProvider from 'components/FormProviders';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useGetVersion, useUpdateVersion } from '../hooks';
import { schema, defaultValues, sanitizeRequest } from '../schemas/referral';

function ReferralTab() {
  const rewardUnits = [{ label: 'percentage', value: 'percentage' }];
  const rewardTypes = [{ label: 'fee', value: 'fee' }];
  const { id } = useParams();
  const { data, isLoading: isFetchingVersion } = useGetVersion({ id });
  const { mutate: updateVersion, isLoading } = useUpdateVersion({ id });
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = (values) => {
    const referralPayload = sanitizeRequest(values);
    const payload = { ...data, referral: referralPayload.referral };
    console.log('payload: ', payload);
    updateVersion(payload);
  };

  useEffect(() => {
    if (data.referral) {
      reset(data);
    }
  }, [data]);

  if (isFetchingVersion) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={2} sm={2} />
      <Grid item xs={8} sm={8}>
        <Card sx={{ overflow: 'visible' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack lineHeight={0} m={3}>
              <SoftTypography variant='h5' fontWeight='bold'>
                Affiliate Settings
              </SoftTypography>
              <SoftTypography
                variant='button'
                fontWeight='regular'
                color='text'
              >
                Set reward for Affiliate user
              </SoftTypography>
            </Stack>
            <Stack m={3}>
              <SoftBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <SelectInputField
                      options={rewardTypes}
                      label={`Reward by`}
                      name={`referral.reward_type`}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <SelectInputField
                      options={rewardUnits}
                      label={`Reward Unit`}
                      name={`referral.reward_unit`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInputField
                      label='Reward Amount'
                      name='referral.reward_amount'
                    />
                  </Grid>
                </Grid>
              </SoftBox>
            </Stack>
            <Stack ml={1}>
              <DialogActions style={{ justifyContent: 'flex-start' }}>
                <SoftButton
                  variant='gradient'
                  color='info'
                  type='submit'
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Update
                </SoftButton>
                <SoftButton variant='gradient' color='error'>
                  Delete
                </SoftButton>
              </DialogActions>
            </Stack>
          </FormProvider>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ReferralTab;
